import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 't1'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyACtk4nQIkvs91acKg5B9b0V3y4qW8tVGM',
    authDomain: 'terrific-deploy.firebaseapp.com',
    projectId: 'terrific-deploy',
    storageBucket: 'terrific-deploy.appspot.com',
    messagingSenderId: '871100133386',
    appId: '1:871100133386:web:ceda850f6d2bab12a5eff3',
    measurementId: 'G-PPHLZ2C8SE',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: true},
  client,
  dockerCompose: false,
};
